<template>
  <div class="progress">
    <div class="progress-bar" :class="value >= 30 ? 'pg-success' : ( value >= 25 ? 'pg-warning' : 'pg-danger')" :style="{ width: `${value}%` }">
      <span :class="value < 25 ? 'progress-txt-center' : ''">{{ `${Math.round(value)}%` }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BarProgress',
  components: {
  },
  props: [
    'value',
  ],
};
</script>
<style lang="scss" scoped>
.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.65625rem;
    background-color: #e3e7ed;
    border-radius: 0rem;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 0rem;
}
.progress-bar {
    font-size: 0.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #9C82D4;
    transition: width 0.6s ease;
}
.pg-success {
  background-color: #168736!important;
}
.pg-warning {
  background-color: #ddb643 !important;
}
.pg-danger {
  background-color: #dc3545!important;
}
.progress-txt-center {
  color: rgb(53, 53, 53);
  position: absolute;
  margin-top: 0px;
  margin-left: 35px;
}
</style>
